<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Stauts</div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3" cols="12">
        <div class="statusTitle">To Do</div>
        <div class="mainDiv">
          <v-row>
            <v-col md="12" cols="12" v-for="item of listData.todo" :key="item">
              <v-card
                class="conOfCardClient"
                color="rgb(54 244 237 / 78%)"
                max-width="200"
              >
                <v-row>
                  <v-col cols="10">
                    <v-card-title>
                      <div class="conOfCardTitle">
                        <div class="Name">{{ item.project_name }}</div>
                        <div class="Name">{{ item.name }}</div>
                      </div>
                    </v-card-title>
                  </v-col>
                  <v-col cols="2">
                    <div class="d-flex justify-end">
                      <v-menu bottom :left="true" :offset-y="true">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn dark icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <template v-for="status in statusList">
                            <v-list-item :key="status">
                              <v-list-item-title
                                class="statusTxt"
                                @click="
                                  updateTaskStatus(status.status, item.id)
                                "
                                >{{ status.title }}</v-list-item-title
                              >
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
                <a @click="openAssignDialog(item.id)" class="d-flex assignTo"
                  >Assign To</a
                >
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col md="3" cols="12">
        <div class="statusTitle">In Progress</div>
        <div class="mainDiv">
          <v-row>
            <v-col
              md="12"
              cols="12"
              v-for="item of listData.inprogress"
              :key="item"
            >
              <v-card
                class="conOfCardClient"
                color="#ff000f59"
                dark
                max-width="200"
              >
                <v-row>
                  <!-- <v-select
                  outlined
                  v-model="status"
                  :items="statusList"
                  item-text="title"
                  item-value="status"
                  placeholder="Status"
                  hide-details="true"
                  @change="updateTaskStatus(status, item.id)"
                  :menu-props="{ bottom: true, offsetY: true }"
                  attach
                  append-icon="mdi-chevron-down"
                ></v-select> -->
                  <v-col cols="10">
                    <v-card-title>
                      <div class="conOfCardTitle">
                        <div class="Name">{{ item.project_name }}</div>
                        <div class="Name">{{ item.name }}</div>
                      </div>
                    </v-card-title>
                  </v-col>
                  <v-col cols="2">
                    <div class="d-flex justify-end">
                      <v-menu bottom :left="true" :offset-y="true">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn dark icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <template v-for="status in statusList">
                            <v-list-item :key="status">
                              <v-list-item-title
                                class="statusTxt"
                                @click="
                                  updateTaskStatus(status.status, item.id)
                                "
                                >{{ status.title }}</v-list-item-title
                              >
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
                <a @click="openAssignDialog(item.id)" class="d-flex assignTo"
                  >Assign To</a
                >
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col md="3" cols="12">
        <div class="statusTitle">Pinding</div>
        <div class="mainDiv">
          <v-row>
            <v-col
              md="12"
              cols="12"
              v-for="item of listData.pending"
              :key="item"
            >
              <v-card class="conOfCardClient" color="#0065ff8a" max-width="200">
                <v-row>
                  <v-col cols="10">
                    <v-card-title>
                      <div class="conOfCardTitle">
                        <div class="Name">{{ item.project_name }}</div>
                        <div class="Name">{{ item.name }}</div>
                      </div>
                    </v-card-title>
                  </v-col>
                  <v-col cols="2">
                    <div class="d-flex justify-end">
                      <v-menu bottom :left="true" :offset-y="true">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn dark icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <template v-for="status in statusList">
                            <v-list-item :key="status">
                              <v-list-item-title
                                class="statusTxt"
                                @click="
                                  updateTaskStatus(status.status, item.id)
                                "
                                >{{ status.title }}</v-list-item-title
                              >
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
                <a @click="openAssignDialog(item.id)" class="d-flex assignTo"
                  >Assign To</a
                >
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col md="3" cols="12">
        <div class="statusTitle">Done</div>
        <div class="mainDiv">
          <v-row>
            <v-col md="12" cols="12" v-for="item of listData.done" :key="item">
              <v-card
                class="conOfCardClient"
                color="#42eba3"
                dark
                max-width="200"
              >
                <v-row>
                  <v-col cols="10">
                    <v-card-title>
                      <div class="conOfCardTitle">
                        <div class="Name">{{ item.project_name }}</div>
                        <div class="Name">{{ item.name }}</div>
                      </div>
                    </v-card-title>
                  </v-col>
                  <v-col cols="2">
                    <div class="d-flex justify-end">
                      <v-menu bottom :left="true" :offset-y="true">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn dark icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <template v-for="status in statusList">
                            <v-list-item :key="status">
                              <v-list-item-title
                                class="statusTxt"
                                @click="
                                  updateTaskStatus(status.status, item.id)
                                "
                                >{{ status.title }}</v-list-item-title
                              >
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
                <a @click="openAssignDialog(item.id)" class="d-flex assignTo"
                  >Assign To</a
                >
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col md="3" cols="12">
        <div class="statusTitle">Review</div>
        <div class="mainDiv">
          <v-row>
            <v-col
              md="12"
              cols="12"
              v-for="item of listData.review"
              :key="item"
            >
              <v-card
                class="conOfCardClient"
                color="rgb(255 243 143)"
                dark
                max-width="200"
              >
                <v-row>
                  <v-col cols="10">
                    <v-card-title>
                      <div class="conOfCardTitle">
                        <div class="Name">{{ item.project_name }}</div>
                        <div class="Name">{{ item.name }}</div>
                      </div>
                    </v-card-title>
                  </v-col>
                  <v-col cols="2">
                    <div class="d-flex justify-end">
                      <v-menu bottom :left="true" :offset-y="true">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn dark icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <template v-for="status in statusList">
                            <v-list-item :key="status">
                              <v-list-item-title
                                class="statusTxt"
                                @click="
                                  updateTaskStatus(status.status, item.id)
                                "
                                >{{ status.title }}</v-list-item-title
                              >
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
                <a @click="openAssignDialog(item.id)" class="d-flex assignTo"
                  >Assign To</a
                >
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col md="3" cols="12">
        <div class="statusTitle">cancel</div>
        <div class="mainDiv">
          <v-row>
            <v-col
              md="12"
              cols="12"
              v-for="item of listData.cancel"
              :key="item"
            >
              <v-card
                class="conOfCardClient"
                color="#f44336c7"
                dark
                max-width="200"
              >
                <v-row>
                  <v-col cols="10">
                    <v-card-title>
                      <div class="conOfCardTitle">
                        <div class="Name">{{ item.project_name }}</div>
                        <div class="Name">{{ item.name }}</div>
                      </div>
                    </v-card-title>
                  </v-col>
                  <v-col cols="2">
                    <div class="d-flex justify-end">
                      <v-menu bottom :left="true" :offset-y="true">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn dark icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <template v-for="status in statusList">
                            <v-list-item :key="status">
                              <v-list-item-title
                                class="statusTxt"
                                @click="
                                  updateTaskStatus(status.status, item.id)
                                "
                                >{{ status.title }}</v-list-item-title
                              >
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
                <a @click="openAssignDialog(item.id)" class="d-flex assignTo"
                  >Assign To</a
                >
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="assignDialog" max-width="700" height="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">Assign To</span>
        </v-card-title>

        <v-card-text class="conFormSubmit">
          <v-container>
            <v-row class="conFormSubmit d-flex justify-center">
              <v-col cols="12">
                <div class="pt-3">
                  <label class="eachLabel">Select Name to Assign Task</label>

                  <v-col cols="12">
                    <v-select
                      outlined
                      v-model="user_id"
                      :items="users"
                      item-text="name"
                      item-value="id"
                      placeholder="Name"
                      hide-details="true"
                      :menu-props="{ bottom: true, offsetY: true }"
                      attach
                      append-icon="mdi-chevron-down"
                      clearable
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeAssignDialog()">
            Cancel
          </v-btn>
          <v-btn class="btnPrimaryOrg" @click="assignTask(selectedTaskId)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      absolute
      bottom
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      bottom
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>

<script setup>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    successSnackbar: false,
    errorSnackbar: false,
    timeout: 3000,
    successMessage: "",
    errorMessage: "",
    users: {},
    assignData: [],
    user_id: "",
    selectedTaskId: "",
    taskId: "",
    assignDialog: false,
    listData: [],
    task_id: "",
    id: "",
    status: "",
    statusList: [
      {
        status: "0",
        title: "todo",
      },
      {
        status: "1",
        title: "PENDING",
      },
      {
        status: "2",
        title: "review",
      },
      {
        status: "3",
        title: "inprogress",
      },
      {
        status: "4",
        title: "done",
      },
      {
        status: "5",
        title: "cancel",
      },
    ],
  }),

  computed: {
    listOne() {
      return this.listData.filter((item) => item.status === 1);
    },
    listTwo() {
      return this.listData.filter((item) => item.status === 2);
    },
    listThree() {
      return this.listData.filter((item) => item.status === 3);
    },
    listFour() {
      return this.listData.filter((item) => item.status === 4);
    },
  },

  methods: {
    getTaskData() {
      this.listData = [];
      this.updateData = [];
      apiServices.get(`my-tasks`).then((res) => {
        if (res) {
          this.listData = res.data;
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },

    // updateTaskStatus() {
    //   this.updateData = null;
    //   this.updateError = false;
    //   this.isLoadingUpdate = true;
    //   let taskID = null;

    //   // loop through each object in the listData object
    //   for (let status in this.listData) {
    //     if (status === this.status && this.listData[status].length > 0) {
    //       taskID = this.listData[status][0].id;
    //       console.log("taskID", taskID);
    //       // use the first task ID in the array
    //       break; // exit the loop
    //     }
    //   }

    //   if (taskID) {
    //     // only make the API call if a task ID was found
    //     apiServices
    //       .put(`status?status=${this.status}&task_id=${taskID}`)
    //       .then((res) => {
    //         if (res) {
    //           this.updateData = res.data;
    //           console.log("updateData", this.updateData);
    //           this.isLoadingUpdate = false;
    //         } else {
    //           this.updateError = true;
    //           this.isLoadingUpdate = false;
    //         }
    //       });
    //   } else {
    //     this.updateError = true;
    //     this.isLoadingUpdate = false;
    //   }
    // },
    updateTaskStatus(status, id) {
      this.updateData = [];
      const payload = {
        status: status,
        task_id: id,
      };
      apiServices.put(`task/status`, payload).then((res) => {
        if (res) {
          this.updateData = res.data;
          console.log("updateData", this.updateData);
          this.getTaskData();
          this.status = "";
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    // resetStatus() {
    //   this.status = "";
    // },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.id);
    },
    onDrop(evt, status) {
      const itemID = evt.dataTransfer.getData("itemID");
      const item = this.listData.find((item) => item.status_id == itemID);
      item.status = status;
    },
    getUserListData() {
      apiServices.get(`user/users`).then((res) => {
        if (res) {
          this.users = res.data;
          console.log("users", this.users);
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    openAssignDialog(task_id) {
      this.selectedTaskId = task_id;
      this.assignDialog = true;
    },

    closeAssignDialog() {
      this.assignDialog = false;
    },
    assignTask() {
      this.assignData = [];

      apiServices
        .post(
          `assign-user-task?&task_id=${this.selectedTaskId}&user_id=${this.user_id}`
        )
        .then((res) => {
          if (res) {
            this.isLoadingSearch = false;
            this.assignData = res.data;
            this.successMessage = res.message;
            this.successSnackbar = true;
            this.closeAssignDialog();
            this.getTaskData();
            this.isLoading = false;
          } else {
            this.errorMessage = res.data.error || "Somthing Error";
            this.errorSnackbar = true;
            this.isLoading = false;
          }
        });
    },
  },

  created() {
    this.getTaskData();
    this.getUserListData();
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
@import "./_taskStatus.scss";
</style>
